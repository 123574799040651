<template>
  <q-dialog ref="modal">
    <q-card class="q-pa-lg">
      <img :src="require('assets/mango.png')">
      <h6 class="text-center q-py-md">
        Coming soon to DerbyGo
      </h6>
      <div class="text-body2 q-mb-sm">
        mango saves you money on bus travel in Derby & across the East Midlands.
      </div>
      <div class="text-body2 q-mb-sm">
        Using our clever little app you scan on as you board, and scan off as you leave.
      </div>
      <div class="text-body2 q-mb-sm">
        We do the rest & make sure you always pay the right fare.
      </div>
      <div class="text-body2 q-mb-sm">
        There are really juicy discounts for students too!
      </div>
      <div class="text-body2 q-mb-sm">
        Go to <a class="mango-link" href="https://www.trentbarton.co.uk/mangoapp">mango</a>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'MangoModal'
}
</script>

<style lang="stylus" scoped>
.mango-link
  text-decoration: underline
</style>
