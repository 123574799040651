<template>
  <q-dialog ref="modal">
    <q-card class="q-pa-lg">
      <h6 class="text-center q-py-md">
        {{ content }}
      </h6>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ComingSoonModal',
  props: {
    content: String
  }
}
</script>
