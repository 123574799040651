export function getWeatherIcon (rawIconName) {
  const globalIcon = rawIconName.replace(/-day|-night/gm, '')
  const nightTime = rawIconName.includes('-night')

  switch (globalIcon) {
  case 'clear':
    return 'fa-sun'
  case 'rain':
    return 'fa-raindrops'
  case 'snow':
    return 'fa-snow-blowing'
  case 'hail':
  case 'sleet':
    return 'fa-cloud-hail'
  case 'wind':
    return 'fa-wind'
  case 'fog':
    return 'fa-fog'
  case 'cloudy':
    return 'fa-clouds'
  case 'partly-cloudy':
    return nightTime ? 'fa-cloud-moon' : 'fa-cloud-sun'
  case 'thunderstorm':
    return 'fa-thunderstorm'
  case 'tornado':
    return 'fa-tornado'
  default:
  }
}

export default { getWeatherIcon }
