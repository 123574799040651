<template>
  <div
    class="welcomeBox shadow-1 row"
  >
    <div class="col-7 column justify-around text-left">
      <p>{{ greeting }}</p>
      <h5 class="primary text-bold">
        {{ displayName || 'Mobilleo traveller' }}
      </h5>
      <p v-if="organisationName">
        {{ organisationName }}
      </p>
    </div>
    <!-- commented out weather box until Darksky is replaced with new weather provider
      TODO: API needs to integrate new weather provider
      <div v-if="weather && weather.current" class="col column justify-around text-right">
      <p><q-icon :name="`fas ${weatherIcon}`" /></p>
      <p class="weatherEllipsis">
        {{ weather.current.temperature.text }} {{ weather.current.summary }}
      </p>
      <p class="weatherEllipsis">
        {{ weather.location | weatherLocation }}
      </p>
    </div>
    <div v-else-if="weather.searching" class="col column justify-around text-right">
      <p v-for="i in 3" :key="i">
        <q-skeleton type="text" />
      </p>
    </div>
    <div v-else-if="!latLng.latitude || !latLng.longitude" class="col column justify-around text-right">
      <p><q-icon name="fas fa-location-slash" /></p>
      <p>{{ $t('dashboard.location_disabled') }}</p>
    </div>
    <div v-else class="col column justify-around text-right">
      <p><q-icon name="fas fa-frown" /></p>
      <p>{{ $t('dashboard.weather_unavailable') }}</p>
    </div> -->
  </div>
</template>
<script>
import dateUtil from 'utils/date-time'
import weatherMixin from 'mixins/weather'
import { mapGetters } from 'vuex'
import store from 'store'

export default {
  filters: {
    weatherLocation (value) {
      const postCodeRegex = /(\b[A-Z]{1,2}\d{1,2}( ?\d?[A-Z]{2})?)(?=,|$)/
      const arr = value.split(',')
      arr.shift()
      let town = arr[0]
      town = town.replace(postCodeRegex, '')
      town = town.trim()
      const country = arr.pop()
      return (town === country) ? country : `${town}, ${country}`
    }
  },
  mixins: [weatherMixin],
  computed: {
    ...mapGetters({
      geoip: 'geoip',
      latLng: 'geolocate/latLng',
      weather: 'weather/all'
    }),
    greeting () {
      const hour = dateUtil.newDate().c.hour
      const greetings = this.$t('dashboard.greetings')

      if (hour < 12) return greetings[0]
      else if (hour < 17) return greetings[1]
      else return greetings[2]
    },
    weatherIcon () {
      const current = this.weather.current
      return current ? this.getWeatherIcon(current.icon) : null
    },
    displayName () {
      return this.$store.getters.authentication.user?.display_name
    },
    organisationName () {
      const org = this.$store.getters.authentication.organisation
      return org ? org.name : null
    }
  },
  watch: {
    latLng: {
      deep: true,
      handler: (value) => {
        store.dispatch('weather/updateWeatherInformation', { lat: value.latitude, lng: value.longitude })
      }
    }
  },
  activated () {
    const lat = this.latLng?.latitude || this.geoip.latitude
    const lng = this.latLng?.longitude || this.geoip.longitude
    store.dispatch('weather/updateWeatherInformation', { lat, lng })
  }
}
</script>
<style lang="stylus" scoped>
.welcomeBox
  z-index -1
  position fixed
  left 0
  right 0
  top 64px
  background-color white
  padding 16px 12px
  max-width 100%

.weatherEllipsis
  width calc(100%)
  text-overflow ellipsis
  white-space nowrap
  overflow hidden

</style>
