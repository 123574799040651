<template>
  <div class="relative-position" style="border-radius: 16px">
    <mango-modal ref="mangoModal" />
    <coming-soon-modal ref="comingSoonModal" :content="comingSoonContent" />

    <!-- Org disabled dialog -->
    <q-dialog
      v-model="orgDisabledDialog"
      @hide="heldItem = null"
    >
      <q-card class="text-center" square>
        <q-card-section class="full-height">
          <p v-html="$t('transport_policy_disabled_detail')" />
          <p v-html="$t('transport_policy_disabled_continue')" />
        </q-card-section>
        <q-card-actions align="center">
          <q-btn v-close-popup color="primary" unelevated :label="$t('back')" />
          <q-btn v-close-popup color="primary" outline :label="$t('continue')" @click="handleClick(heldItem)" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Weather/Greeting box  -->
    <greeting-box />

    <!-- Map center buttons -->
    <div class="column absolute" style="right: 16px; top: -72px">
      <q-btn
        fab
        icon="far fa-location"
        class="q-mb-md location-btn"
        @click="setMapToGeolocation"
      />
    </div>

    <!-- Bottom sheet content -->
    <div ref="nearby" class="bg-white nearby">
      <!-- SmartSearch button -->
      <button
        rounded
        outline
        unelevated
        no-caps
        class="smartsearch-btn"
        @click="$router.push({
          name: 'smart-search',
          params: {
            origin: {
              label: 'Current Location',
              latitude: latLng.latitude || geoip.latitude,
              longitude: latLng.longitude || geoip.longitude,
            }
          }
        }).catch(() =>{})"
      >
        <q-avatar size="16px">
          <img src="~assets/mobilleo-logo-icon.png" alt="">
        </q-avatar>

        <em>{{ $t('where_to') }}</em> {{ $t('smartsearch.plan_your_journey_here') }}
      </button>
      <div class="text-center">
        <em>{{ $t('know_where_youre_going') }}</em> {{ $t('book_now') }}
      </div>

      <q-dialog
        v-if="['derbygo'].includes(authPartner.slug)"
        v-model="showSociabilityDialog"
        transition-show="jump-up"
        transition-hide="jump-down"
        @ok="externalUrl(authPartner.sociabilityUrl)"
      >
        <q-card class="booking-complete bg-primary text-white text-center" square>
          <q-card-section class="full-height">
            <q-avatar size="42px" rounded>
              <img :src="require(`assets/sociability.png`)" alt="sociability icon">
            </q-avatar>
            <p v-html="$t('derby.sociability_introduction')" />
          </q-card-section>
          <q-card-actions>
            <q-btn v-close-popup color="secondary" unelevated rounded class="full-width" :label="$t('derby.sociability_link')" @click.stop="externalUrl(authPartner.sociabilityUrl)" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Mobility Nearby buttons -->
      <div :class="chooseGridClass(mobilityAndBookablesCombined.length)">
        <div
          v-for="(item, key) in mobilityAndBookablesCombined"
          :key="key"
          class="text-center"
          @click="isTypeEnabled(item) ? isPolicyEnabled(item) ? handleClick(item) : showOrgDisabledDialog(item) : null"
        >
          <q-btn
            :id="`mobilityNearbyButton${key}`"
            unelevated round
            :aria-labelledby="key"
            class="q-mb-sm nearby-icon"
          >
            <m-travel-icon :type="item" color="white" size="48px" :bg-color="isTypeEnabled(item) ? getTravelContentHex(item) : '#757575'" circle />
          </q-btn>
          <div :id="key" class="text-subtitle2">
            {{ $tc(`content_type.${item}`) }}
          </div>
        </div>
      </div>
      <div v-if="['gohi', 'swift'].includes(authPartner.slug)" class="text-center">
        <m-cta-button @ctaClick="buyTicket">
          <q-icon name="fas fa-ticket-alt" class="q-mr-sm" size="17px" />
          {{ $t('public_transport_ticket') }}
        </m-cta-button>
      </div>

      <!-- Explore Nearby buttons -->
      <div class="text-center">
        <em>{{ $t('explore_nearby') }}</em>
      </div>
      <div class="three-grid">
        <div v-for="(item, key) in nearby" :key="key" class="text-center">
          <template v-if="getTravelContentIcon(item) === 'asset'">
            <q-btn
              v-if="getTravelPreferenceName(item) === 'dialog'"
              unelevated round
              class="q-mb-sm nearby-icon"
              @click="displayDialog(item)"
            >
              <q-avatar size="42px" rounded>
                <img :src="require(`assets/${getAssetPath(item)}`)" alt="">
              </q-avatar>
            </q-btn>
            <q-btn
              v-else
              unelevated round
              class="q-mb-sm nearby-icon"
              @click="handleClick(item)"
            >
              <q-avatar size="42px" rounded>
                <img :src="require(`assets/${getAssetPath(item)}`)" alt="">
              </q-avatar>
              <!-- TODO: S3 variant of <q-avatar> where the require() doesn't die -->
            </q-btn>
            <div class="text-subtitle2">
              {{ $tc(`content_type.${item}`) }}
            </div>
          </template>
          <template v-else>
            <q-btn
              unelevated round
              :icon="getTravelContentIcon(item)"
              text-color="primary"
              @click="handleClick(item)"
            />
            <div class="text-subtitle2">
              {{ $tc(`content_type.${item}`) }}
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- DerbyGo concierge -->
    <template v-if="authPartner && authPartner.slug === 'derbygo'">
      <q-banner dense class="bg-primary q-py-md q-pa-lg" @click="$router.push({path: 'profile', hash: '/contact', query: { type: 'question' } })">
        <template v-slot:avatar>
          <q-icon name="fas fa-comments-alt" size="32px" class="q-mr-sm" />
        </template>
        <div class="row">
          <div class="col-11">
            <div class="text-bold">
              {{ $t('derby.need_help') }}
            </div>
            <div class="text-subtitle2">
              {{ $t('derby.contact_us') }}
            </div>
          </div>
          <div class="col-1 text-right self-center">
            <q-icon name="fas fa-angle-right" size="24px" />
          </div>
        </div>
      </q-banner>
    </template>
    <!-- Other concierge -->
    <template v-else>
      <q-banner dense class="bg-primary q-py-md q-pa-lg" @click="$router.push({path: 'profile', hash: '/concierge', query: { type: 'question' } })">
        <template v-slot:avatar>
          <q-icon name="fas fa-concierge-bell" size="32px" class="q-mr-sm" />
        </template>
        <div class="row">
          <div class="col-11">
            <div class="text-subtitle2">
              {{ $t('cannot_find') }}
            </div>
            <div class="text-bold">
              {{ $t('concierge.always_here') }}
            </div>
          </div>
          <div class="col-1 text-right self-center">
            <q-icon name="fas fa-angle-right" size="24px" />
          </div>
        </div>
      </q-banner>
    </template>
  </div>
</template>

<script>
import greetingBox from './greeting.vue'
import authentication from 'mixins/authentication'
import travelContents from 'mixins/travelContents'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import mangoModal from './mango-modal'
import comingSoonModal from './coming-soon-modal'
import { MTravelIcon, MCtaButton } from 'components'
import { hasRole } from 'utils/authentication'

export default {
  name: 'Home',
  components: {
    mangoModal,
    comingSoonModal,
    greetingBox,
    MTravelIcon,
    MCtaButton
  },
  mixins: [authentication, travelContents],
  data () {
    return {
      showSociabilityDialog: false,
      orgDisabledDialog: false,
      heldItem: null,
      comingSoonContent: 'Coming soon'
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      geoip: 'geoip',
      latLng: 'geolocate/latLng',
      authPartner: 'partner',
      organisation: 'organisation',
      travelPreferences: 'preferences',
      roles: 'roles'
    }),
    bookables () {
      return this.authPartner.bookables
    },
    nearby () {
      return this.authPartner.nearby
    },
    mobilityAndBookablesCombined () {
      let arr = [...this.authPartner.mobility, ...this.authPartner.bookables]
        .filter(item => !this.travelContentExcluded(item, ['mobilityNearby']))
      return arr
    }
  },
  activated () {
    // Start loading saved addresses in the background
    // Only if user isn't a guest
    if (!hasRole('guest')) this.$store.dispatch('addresses/getSavedLocations')

    // Tidy up any leftovers from map and booking searches
    this.$store.dispatch('map/setSearchLocation', { latitude: null, longitude: null })
    this.$emit('markers', [])
    this.$store.commit('ondemand/clear')

    // This is here to automatically set map to Inverness for Go-Hi demo purposes
    if (this.hasPermission('feature.inverness.home')) this.$store.dispatch('geolocate/setMapToInverness')

    // Scroll the bottomsheet into view
    const sheet = document.getElementById('sheet')
    sheet.parentNode.parentNode.scrollTo({
      behavior: 'smooth',
      top: this.$refs.nearby.getBoundingClientRect().height - 360
    })

    // Not sure what this is doing?
    if (this.$route.params.booking_reference) loading.stop()
  },
  methods: {
    chooseGridClass (length) {
      if (length % 4 === 0) {
        return 'four-grid'
      }
      if (length % 3 === 0) {
        return 'three-grid'
      }
      return 'five-grid'
    },
    externalUrl (url) {
      if (url) window.open(url, '_blank')
    },
    displayDialog (name) {
      switch (name) {
      case 'sociability':
        this.showSociabilityDialog = true
      }
    },
    setMapToGeolocation (location) {
      let lat
      let lng

      if (location) {
        lat = location.lat
        lng = location.lng
      }
      this.$root.$emit('setMap', (map) => {
        map.setZoom(13)
        map.panTo({
          lat: lat || this.latLng.latitude || this.geoip.latitude,
          lng: lng || this.latLng.longitude || this.geoip.longitude
        })
      })
    },
    handleClick (item) {
      // If org disabled dialog is showing, hide it now
      this.orgDisabledDialog = false

      // If item has an external url, go there now
      const url = this.getUrl(item)
      if (url) {
        return this.externalUrl(url)
      }

      // Mango
      if (item === 'mango') {
        if (!this.hasPermission('feature.mbapi.312.mango')) {
          return this.$refs.mangoModal.$refs.modal.show()
        }
        return this.$router.push({ hash: '/mango' })
      }

      // Taxi coming soon for derbygo
      if (item === 'ride-hailing' && this.$store.getters['partner'].slug === 'derbygo') {
        this.comingSoonContent = this.$t('derby.taxi_coming_soon')
        return this.$refs.comingSoonModal.$refs.modal.show()
      }

      if (item === 'drt' || item === 'dial-m') {
        if (this.authPartner.slug === 'gohi') {
          // DRT shutdown for gohi
          this.comingSoonContent = this.$t('drt.dial_m_coming_soon')
          return this.$refs.comingSoonModal.$refs.modal.show()
        } else {
          // Generic 'coming soon' message for everyone else
          this.comingSoonContent = this.$t('drt.generic_coming_soon')
          return this.$refs.comingSoonModal.$refs.modal.show()
        }
      }

      const latitude = this.latLng.latitude || this.geoip.latitude
      const longitude = this.latLng.longitude || this.geoip.longitude
      if (['taxi', 'ride-hailing', 'ridehailing', 'hotel', 'drt'].includes(item)) {
        return this.$router.push({
          name: this.getTravelContentRoute(item, 'search'),
          params: {
            origin: {
              label: this.$t('current_location'),
              latitude,
              longitude,
              value: `${latitude},${longitude}`
            }
          }
        }).catch(() => {})
      }

      return this.$router.push({
        name: 'home-cards',
        params: {
          type: this.getTravelPreferenceName(item),
          label: this.$t(`content_type.${item}`),
          icon: this.getMapIcon(item),
          color: this.getTravelContentHex(item),
          distance: this.getTravelContentRadius(item)
        }
      })
    },
    showOrgDisabledDialog (item) {
      this.heldItem = item
      this.orgDisabledDialog = true
    },
    buyTicket () {
      this.$store.commit('ondemand/clear')
      this.$router.push({ name: 'public-transport-form' })
    }
  },
  beforeRouteLeave (to, from, next) {
    window.scrollTo(0, 0)
    if (to.name === 'home-search') {
      this.$root.$children[0].$refs.layout.$refs.sheet.scrollToBottom()
      setTimeout(next, 100)
    } else {
      next()
    }
  }
}
</script>

<style lang="stylus" scoped>
.text-white
  color #fff

.location-btn
  background-color white
  color var(--q-color-primary)

.nearby
  z-index 10
  padding 16px
  border-radius 16px
  padding-top 24px
  display grid
  grid-auto-rows auto
  grid-row-gap 24px
  align-items center

.five-grid
  display grid
  grid-template-columns repeat(5, 1fr)
  grid-row-gap 16px

.four-grid
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-row-gap 16px

.three-grid
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-row-gap 16px

.smartsearch-btn
  justify-self center
  width 95%
  background-color white
  color #666
  border solid #ddd 0.5px
  padding 8px 0
  border-radius 16px
  display flex
  align-items center
  justify-content center
  em
    margin 0 4px 0 8px

em
  color var(--q-color-primary)

</style>
