<template>
  <q-card square flat>
    <q-toolbar class="text-black q-pa-md absolute z-top">
      <q-btn flat round dense icon="arrow_back" class="bg-primary" @click="$router.go(-1)" />
    </q-toolbar>
    <m-image v-if="detail.photos" :src="`${url}/locations/places/photo/${detail.photos[0].photo_reference}?max_height=275`" height="275" />

    <q-card-section>
      <q-btn
        fab
        color="primary"
        icon="far fa-route"
        class="absolute"
        style="top: 0; right: 12px; transform: translateY(-50%);"
      />

      <div class="row no-wrap items-center">
        <div class="col text-h6 ellipsis">
          {{ detail.name }}
        </div>
      </div>

      <q-rating :value="detail.reviews ? detail.reviews[0].rating : 0" :max="5" size="32px" />
    </q-card-section>

    <q-card-section class="q-pt-none">
      <div class="text-subtitle1">
        {{ detail.formatted_address }}
      </div>
    </q-card-section>

    <q-separator />

    <q-card-actions>
      <q-btn flat color="primary">
        {{ $t('book_now') }}
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { details } from 'api/places'
import { MImage } from 'components/'

export default {
  components: { MImage },
  data () {
    return {
      detail: null
    }
  },
  computed: {
    ...mapGetters({
    }),
    url () {
      return process.env.VUE_APP_API_URL
    }
  },
  beforeRouteEnter (to, from, next) {
    details(to.params.reference).then(response => {
      next(vm => {
        vm.$emit('loading', false)
        vm.detail = response.data
      })
    })
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  margin 0
  min-height 100vh
</style>
